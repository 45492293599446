import styled from 'styled-components'

export const Worker = styled.p`
  display: inline;
  padding-bottom: 5px;
  border-bottom: 2px solid #e54435;
`

export const Title = styled.p`
  padding-top: 15px;
`

export const List = styled.ul``

export const ListItem = styled.li`
  position: relative;
  padding-left: 15px;
  &:after {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    left: 0;
    display: block;
    background: #000;
    top: 9px;
  }
`
