import styled from 'styled-components'
import { largeBreakpoint } from '../../../config/css-constants'

export const Header = styled.section`
  padding: 50px 0;
  @media all and (min-width: ${largeBreakpoint}) {
    padding-top: 50px;
  }
`

export const HeaderInfo = styled.h2`
  font-weight: 500;
  @media all and (min-width: ${largeBreakpoint}) {
    max-width: 50%;
  }
`
