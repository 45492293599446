import React from 'react'
import { graphql } from 'gatsby'
import CareerSimpleEmployee from '../components/CustomCareer/CareerSingleEmployee/CareerSingleEmployee'
import workers from '../components/CustomCareer/career-config'
import ContactUsLink from '../components/ReusableComponents/ContactUsLink'
import CareerEmployees from '../components/CustomCareer/CareerEmployees/CareerEmployees'
import styled from 'styled-components'
import { fullhd } from '../config/css-constants'

const Wrapper = styled.div`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0px;
  @media screen and (min-width: ${fullhd}) {
    margin-left: 64px;
    margin-right: 64px;
    padding-left: 7rem;
    padding-right: 3rem;
  }
`

const Template = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark

  return (
    <Wrapper>
      <CareerSimpleEmployee data={frontmatter} weOffer={workers.weOffer} />
      <CareerEmployees />
      <ContactUsLink />
    </Wrapper>
  )
}

export const query = graphql`
  query ($pathSlug: String!) {
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      frontmatter {
        title
        duties
        responsibilities
        expectations
        niceToSee
      }
    }
  }
`

export default Template
