import React from 'react'
import { Worker } from './styles'

import CareerApplication from '../CareerApplication/CareerApplication'
import CareerInformationHeader from '../CareerInformationHeader/CareerInformationHeader'
import List from './List'
import SEO from '../../Seo'

const CareerSingleEmployee = (props) => {
  const {
    data: { responsibilities, expectations, niceToSee, duties, title },
    weOffer,
  } = props

  return (
    <>
      <CareerInformationHeader duties={duties} />
      <SEO title={title}/>
      <Worker>{title}</Worker>
      <List title="Responsibilities:" items={responsibilities} />
      <List title="Expectations:" items={expectations} />
      <List title="Nice to see:" items={niceToSee} />
      <List title="We offer:" items={weOffer} />
      <CareerApplication />
    </>
  )
}

export default CareerSingleEmployee
