import React from 'react'
import { Title, PersonalData } from './styles'

const CareerApplicationSection = () => (
  <div>
    <Title>How to apply?</Title>
    <p>
      If you think that you fulfill the above-outlined requirements, send us
      your CV, the list of your projects, or a weblink to your online portfolio
      (GitHub, Bitbucket) with the following statement of consent to the
      processing of personal data:
    </p>
    <PersonalData>
      „I agree to the processing of personal data provided in this document for
      realising the recruitment process pursuant to the Personal Data Protection
      Act of 10 May 2018 (Journal of Laws 2018, item 1000) and in agreement with
      Regulation (EU) 2016/679 of the European Parliament and of the Council of
      27 April 2016 on the protection of natural persons with regard to the
      processing of personal data and on the free movement of such data, and
      repealing Directive 95/46/EC (General Data Protection Regulation)”.
    </PersonalData>
  </div>
)

export default CareerApplicationSection
