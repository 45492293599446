import styled from 'styled-components'

export const Title = styled.p`
  padding-top: 15px;
`

export const PersonalData = styled.p`
  padding: 50px 0;
  position: relative;
  font-style: italic;
  &:after {
    content: '';
    border-bottom: 1px solid #000;
    width: 100%;
    height: 1px;
    display: block;
    position: absolute;
    bottom: 30px;
  }
`
