import React from 'react'
import { ListItem, Title, List as StyledList } from './styles'

const List = (props) => {
  const { items, title } = props

  const itemList = items.map((singleInput, index) => (
    <ListItem key={index}>{singleInput}</ListItem>
  ))

  return (
    !!itemList.length && (
      <>
        <Title>{title}</Title>
        <StyledList>{itemList}</StyledList>
      </>
    )
  )
}

export default List
