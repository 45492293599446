import React from 'react'
import { Header, HeaderInfo } from './styles'

const CareerInformationHeader = (props) => (
  <Header>
    <HeaderInfo>{props.duties}</HeaderInfo>
  </Header>
)

export default CareerInformationHeader
