const workers = {
  weOffer: [
    'competitive salary ',
    'relaxed working environment',
    'availability to pursue your side portfolio',
    'work from home & work/life balance',
    'lunches',
    'social events throughout the year',
    'conference budget',
  ],
}

export default workers
